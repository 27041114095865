import { Component, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDialogDelete from "@/components/UlDialogDelete.vue";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import { SearchRequest } from "@/api/shop-ticket/request";
import { SearchItem } from "@/api/shop-ticket/response";
import ShopTicketSearch from "@/store/shop-ticket/search";
import ShopTicketGet from "@/store/shop-ticket/get";
import Flash, { ErrorAlert } from "@/store/common/flash";
import Loading from "@/store/loading";

@Component({
  components: { UlContentHeader, UlBreadcrumbs, UIDataTable, UIDialogDelete }
})
export default class Index extends Vue {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "デジタルチケット";
  headingSub = "Digital ticket";
  breadCrumbs = [
    { text: "アピール", disabled: true },
    { text: "デジタルチケット", disabled: true },
    { text: "店舗一覧", disabled: true }
  ];
  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "店舗ID", value: "id" },
    { text: "店舗名", value: "name" },
    { text: "", value: "space", sortable: false },
    {
      label: "デジタルチケット一覧",
      text: "",
      value: "action",
      sortable: false
    }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------

  // 検索入力オプション
  inputOptions = {
    searchShopId: null as number | null,
    selectShopIds: null as number[] | null
  };

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = DEFAULT_TABLE_OPTIONS;

  // ------------

  get loading() {
    return Loading.isLoading;
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return ShopTicketSearch.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return ShopTicketSearch.getTotalCount;
  }

  get shopItems() {
    return ShopTicketGet.getItems;
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    await ShopTicketSearch.restore(this.inputOptions as SearchRequest);
    const request = ShopTicketSearch.getSearchRequest;
    // 検索入力オプション
    request.searchShopId &&
      (this.inputOptions.searchShopId = request.searchShopId);
    request.selectShopIds &&
      (this.inputOptions.selectShopIds = request.selectShopIds);

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
    await this.search();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopTicketSearch.clearResponse();
    await ShopTicketGet.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    await Flash.clear();
    await this.search();
  }

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   *
   * @param item 選択行のSearchItem
   */
  async actionClickCallback(item: SearchItem) {
    await this.$router.push({
      name: "ticket-list",
      params: { shopId: item.id },
      query: { shopName: item.name }
    });
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    if (ShopTicketGet.isSuccess) {
      return true;
    }

    await ShopTicketGet.get();
    if (!ShopTicketGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopTicketGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    return ShopTicketGet.isSuccess;
  }

  /**
   * 検索処理
   */
  async search() {
    let isSuccess = await this.fetchShopList();
    if (!isSuccess) {
      return;
    }

    let request = this.createRequest();
    await ShopTicketSearch.search(request);
    if (!ShopTicketSearch.isSuccess) {
      await Flash.setErrorNow({
        message: ShopTicketSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SearchRequest {
    const request = this.tableOptions as SearchRequest;
    if (this.inputOptions.searchShopId) {
      request.searchShopId = this.inputOptions.searchShopId;
    } else {
      delete request.searchShopId;
    }

    if (this.inputOptions.selectShopIds) {
      request.selectShopIds = this.inputOptions.selectShopIds;
    } else {
      delete request.selectShopIds;
    }

    return request;
  }
}
